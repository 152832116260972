<template>
  <div class="card card-custom" :class="{ 'gutter-b': included }">
    <div class="card-header border-0 pt-6 pb-0 d-flex">
      <div class="card-title">
        <h3 class="card-label">{{ $t('Subscriptions') }}
          <span v-if="included" class="d-block text-muted pt-2 font-size-sm">{{
            $t("To purchase reports and maps select the country in the reports section")
          }}</span>
        </h3>
      </div>
    </div>
    <div class="card-body detail table-subscription">
      <datatable ref="datatableRef" :table-props="tableProps" :options.sync="tableOptions"
        :per-page-options="perPageOptions" thead-hidden>

        <template #row-details="{ row: { item: rowItem } }">
          <div class="separator separator-dashed"></div>
          <table class="datatable-table b-table w-100 border-left border-right border-bottom">
            <thead class="datatable-head">
              <tr class="datatable-row text-muted text-uppercase">
                <th class="datatable-cell datatable-cell-sort font-size-sm"></th>
                <th class="datatable-cell datatable-cell-sort font-size-sm">{{ $t('Type') }}</th>
                <th class="datatable-cell datatable-cell-sort font-size-sm">{{ $t('Status') }}</th>
                <th class="datatable-cell datatable-cell-sort font-size-sm">{{ $t('Expiration Date') }}</th>
                <th class="datatable-cell datatable-cell-sort font-size-sm">{{ $t('Renewal Date') }}</th>
                <th class="datatable-cell datatable-cell-sort font-size-sm">{{ $t('Auto Renewal') }}</th>
                <th data-autohide-disabled="false" class="datatable-cell datatable-cell-sort font-size-sm">{{
                  $t('Renewal') }}</th>
              </tr>
            </thead>
            <tbody class="datatable-body">
              <tr v-for="sub in getActiveSubscriptionForCountry(rowItem.country)" :key="`${rowItem.id}-${sub.id}`">
                <td class="datatable-cell"></td>
                <td class="datatable-cell">{{ sub.name }}</td>
                <td class="datatable-cell">
                  <div class="d-flex align-items-center">
                    <span class="label label-dot label-lg"
                      :class="{ 'label-success': sub.active, 'label-danger': !sub.active }">
                    </span>
                    <span class="font-weight-bold ml-2"
                      :class="{ 'text-success': sub.active, 'text-danger': !sub.active }">{{
                        sub.active ? "Active" : "Not Active" }}</span>
                  </div>
                </td>
                <td class="datatable-cell">{{ !sub.autoRenewal ? DateService.format(sub.expiringDate, dateFormat) : ''
                }}</td>
                <td class="datatable-cell">{{ sub.autoRenewal ? DateService.format(sub.expiringDate, dateFormat) : '' }}
                </td>
                <td class="datatable-cell">
                  <span class="label label-dot mr-2"
                    :class="{ 'label-success': sub.autoRenewal, 'label-danger': !sub.autoRenewal }"></span>
                  <span class="font-weight-bold"
                    :class="{ 'text-success': sub.autoRenewal, 'text-danger': !sub.autoRenewal }">{{
                      sub.autoRenewal ? $t("Yes") : $t("No")
                    }}</span>
                </td>
                <td class="datatable-cell">
                  <b-button v-b-tooltip.hover class="btn btn-icon btn-light btn-hover-primary btn-sm mr-2"
                    :title="sub.autoRenewal ? $t('Deactivate Auto Renewal') : $t('Activate Auto Renewal')"
                    @click="autoRenewal(sub.id, sub.autoRenewal)">
                    <span class="svg-icon svg-icon-md svg-icon-primary">
                      <inline-svg v-if="sub.autoRenewal" src="/media/svg/icons/Media/Pause.svg" />
                      <inline-svg v-else src="/media/svg/icons/Media/Repeat.svg" />
                    </span>
                  </b-button>
                </td>
              </tr>
            </tbody>
          </table>
        </template>

        <template #[`cell.country`]="{ item }">
          <div class="text-muted text-uppercase font-weight-bold mb-2">{{ $t("Country") }}</div>
          <div class="d-flex align-items-center">
            <country-flag class="mr-4" :country-iso="item.country"></country-flag>
            <span class="font-weight-bold text-uppercase">{{ $t(countriesMap[item.country]?.name) }}</span>
          </div>
        </template>

        <template #[`cell.map`]="{ item }">
          <div class="text-muted text-uppercase font-weight-bold mb-2">{{ $t("Risk Map") }}</div>
          <div class="d-flex align-items-center">
            <span class="label label-dot label-lg" :class="{ 'label-success': item.map, 'label-danger': !item.map }">
            </span>
            <span class="font-weight-bold ml-2" :class="{ 'text-success': item.map, 'text-danger': !item.map }">{{
              item.map ? "Yes" : "No" }}</span>
          </div>
        </template>

        <template #[`cell.report`]="{ item }">
          <div class="text-muted text-uppercase font-weight-bold mb-2">{{ $t("Reports") }}</div>
          <div class="d-flex align-items-center">
            <span class="label label-dot label-lg"
              :class="{ 'label-success': item.report, 'label-danger': !item.report }">
            </span>
            <span class="font-weight-bold ml-2" :class="{ 'text-success': item.report, 'text-danger': !item.report }">{{
              item.report ? "Yes" : "No" }}</span>
          </div>
        </template>

        <template #[`cell.actions`]="{ item }">
          <b-button v-if="included" v-b-tooltip.hover :title="$t('Go to Reports')"
            class="btn btn-light-primary font-weight-bolder" @click="changeCountryIso(item.country)">
            {{ $t('Go to Reports') }}
          </b-button>
          <router-link v-else :id="`link-${item.id}`" :to="{
            name: 'reports',
            params: item.country,
            query: { iso: item.country },
            hash: '#report'
          }" class="btn btn-light-primary font-weight-bolder">
            {{ $t('Go to Reports') }}
          </router-link>
          <b-tooltip :target="`link-${item.id}`" triggers="hover">
            {{ $t('Go to Reports') }}
          </b-tooltip>
        </template>
      </datatable>
    </div>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import DateService from "@/core/services/date.service";
import { mapGetters, mapState, mapActions } from "vuex";
import CountryFlag from "@/view/components/CountryFlag.vue";
import icons from "@/core/config/icons.js";
import PostAccessService from "@/core/services/risk/post-access.service";
import { successToast } from '@/core/helpers';

export default {
  components: {
    CountryFlag
  },
  props: {
    included: {
      type: Boolean,
      default: false,
    },
    filterFields: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      icons,
      DateService,
      isLoading: true,
      fields: [
        { label: this.$t("Country"), key: "country", sortable: true, class: 'border-left pl-6 align-top', variant: 'secondary' },
        { label: this.$t("Reports"), key: "report", sortable: true, class: "align-top", variant: 'secondary' },
        { label: this.$t("Risk Map"), key: "map", sortable: true, class: "align-top", variant: 'secondary' },
        { label: this.$t("Actions"), key: "actions", class: "align-end col-actions border-right pr-6 text-right", variant: 'secondary' },
      ],
      perPageOptions: [10, 15, 25],
      tableOptions: {
        "sort-by": "country",
        "sort-desc": false,
        "per-page": 10,
        "current-page": 1,
      }
    };
  },

  computed: {
    ...mapState("capabilities", ["capabilities"]),
    ...mapGetters("capabilities", ["getActiveSubscriptionForCountry"]),
    ...mapGetters("user", ["dateFormat"]),
    ...mapGetters("constants", ["countriesMap"]),
    items() {
      let items = []
      let id = 0;
      this.capabilities.forEach(element => {
        let tmpItem = {
          id: id++,
          country: element.country,
          map: false,
          report: false,
          subscrption: element,
          _showDetails: true
        }
        element.categories.forEach(category => {
          if (category.id == 117) {
            tmpItem.map = true
          } else {
            tmpItem.report = true
          }
        })
        items.push(tmpItem)
      });

      return items;
    },
    tableProps() {
      return {
        busy: this.isLoading,
        fields: this.fields,
        items: this.items
      };
    },
  },

  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: this.$t("Subscriptions") },
    ]);
    this.isLoading = false;
  },
  methods: {
    ...mapActions("capabilities", ["loadCapabilities"]),
    changeCountryIso(iso) {
      this.$emit("iso", iso);
    },
    async autoRenewal(id, value) {
      await PostAccessService.editPostSubscription(id, { auto_renewal: !value }).then(() => {
        this.loadCapabilities();
        successToast(this.$t("Subscription updated"));
      })
    }
  }
};
</script>

<style>
.table-subscription .table.b-table>tbody>tr.b-table-details>td {
  padding-top: 0;
}
</style>

